import React, { useState, setIsOpen } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import logo from '../img/logo/Logo.png';
import {Link} from 'react-router-dom';
import {HashLink } from "react-router-hash-link";
import { RiHome3Line } from "react-icons/ri";
import { FiMail } from "react-icons/fi";
import { HiOutlineInformationCircle } from "react-icons/hi2";

function BasicExample() {
  // const apiUrl = process.env.REACT_APP_API_URL;
  const [isOpen, setIsOpen] = useState(true);
    const handleLinkClick = () => {
      setIsOpen(false); // Close menu after clicking a link
    };
  

  return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Link to="./"><img src={logo} alt="logo" className="logo_img" width={"12%"}  />
        <span className="logo-text">AblePro Solutions</span>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <HashLink spy="true" smooth={true} duration={500} onClick={handleLinkClick} className='nav-item-style'  to="/#Home"> 
            <RiHome3Line />  HOME </HashLink>
           <HashLink spy="true" smooth={true} duration={500} onClick={handleLinkClick} className='nav-item-style'  to="/#About"><HiOutlineInformationCircle /> ABOUT </HashLink>
           <HashLink spy="true" smooth={true} duration={500} onClick={handleLinkClick} className='nav-item-style'  to="/#Contact"><FiMail /> CONTACT </HashLink>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default BasicExample;