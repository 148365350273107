import React, { useEffect } from 'react';
import {Route,BrowserRouter,Switch} from 'react-router-dom';
import Home from './components/Home';
import ScrollToTop from './components/ScrollToTop';
import BackToTopButton from './components/BackToTopButton';
import Navbar from './components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


const App = () => {
  
  // const apiUrl = process.env.REACT_APP_API_URL;
  // console.log(apiUrl);

  useEffect(() => {
    AOS.init({
      // Global settings here
    });
  }, []);

  return (
    <BrowserRouter>
    <>
   
    <ScrollToTop />
      
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        
      </Switch>
    </>
    
    <BackToTopButton />
    </BrowserRouter>
  )
}

export default App


