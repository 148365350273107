import React, { useRef } from "react";
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'aos/dist/aos.css';
import banner from '../img/logo/banner-bg.png';
import amogh from '../img/logo/amogh.png';
import designright from '../img/logo/designright.png';
import designleft from '../img/logo/designleft.png';
import top from '../img/logo/left_top.png';
import bottom from '../img/logo/left_bottom.png';
import right_top from '../img/logo/right_top.png';
import right_bottom from '../img/logo/right_bottom.png';
import Guddushastri from '../img/logo/Guddushastri.png';
import Indumathi from '../img/logo/Indumathi.png';
import Shailaja from '../img/logo/Shailaja.png';
import Vishnukant from '../img/logo/Vishnukant.png';
import Karan from '../img/logo/Karan.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiMail } from "react-icons/fi";
import { IoMdCall } from "react-icons/io";
import 'react-medium-image-zoom/dist/styles.css';



function Home() {
  const section1 = useRef(null);
  return (
<>
{/* <!-- ======= Start Home Section ======= --> */}
<section id='Home' className="home-bg gap1">
<img src={banner} alt="banner" className="logo_icon aos-init  aos-animate" data-aos="fade-up" width={"200"} />
<h1 className="logo-heading text-center pt-2 aos-init  aos-animate" data-aos="fade-up">AblePro Solutions</h1>
<p className="logo-para text-center pt-1 aos-init  aos-animate" data-aos="fade-up">Enable, Engage, Empower</p>
</section>

    {/* <!-- ======= End Home Section ======= --> */}

    {/* <!-- ======= Start About Section ======= --> */}

    <section id="About" className="about-bg">
    <img src={designright} alt="banner" className="rightdesign" width={"20%"} />
    <img src={top} alt="banner" className="topdesign" width={"20%"} />
      <Container className="aos-init  aos-animate" data-aos="fade-up">
        <Row>
        <div className="gap" >
      <h1 className="about-heading aos-init  aos-animate" data-aos="fade-up">Namaste!</h1>
      <p className="about-para pt-3 aos-init  aos-animate" data-aos="fade-up">We are <b>AblePro Solutions Pvt. Ltd.</b>, a startup company registered on September 2024,
with objectives to design, develop, provide, host mobile and web applications and carry on the
business of providing assistive technology services and technology platforms for the disability
sector and conducting research in overcoming everyday challenges of people with disabilities
and set up infrastructures for assistive technology products.</p><br/>
<p className="about-para aos-init  aos-animate" data-aos="fade-up">Our core beliefs with respect to our work — <i>‘Enable, Engage, Empower.’</i> </p>
    </div>
    </Row>
      </Container>
    </section>
{/* <!-- ======= End About Section ======= --> */}

{/* <!-- ======= Start Amogh_Inamdar Section ======= --> */}
<section className="home-bg">
<img src={bottom} alt="banner" className="bottomdesign" width={"20%"} />
<img src={right_top} alt="banner" className="righttopdesign" width={"20%"} />
  <Container >
  <div className="gap"> 
   <Row> 
      
    <Col lg={4} md={4} sm={12} xs={12} className=" aos-init aos-animate" data-aos="fade-up">
    <div className="amogh-img">
    <img src={amogh} alt="amogh" width={"100%"} />
    <h2 className="amog-heading pt-2">Amogh Inamdar</h2>
    <h4 className="amog-sub-heading">M.Des, IDC, IIT Bombay</h4>
    <p className="amog-para">Founder, Director</p>
    </div>
      </Col>

      <Col lg={8} md={8} sm={12} xs={12} className=" aos-init aos-animate" data-aos="fade-up">
          <p className="amog-parag pt-1">‘‘As a person with a disability, I have faced and overcome numerous challenges throughout my life. This journey has fuelled my desire to build a comprehensive technology platform that serves the needs of people with disabilities across and strengthening our community. I am committed to listening to the current challenges and finding innovative
          solutions to address them.</p><br/>
          <p className="amog-parag pt-1">I never set out to create a company, but I have always dreamed big. One of the most 
impactful statements I carry with me is from a professor at IIT Bombay:
‘‘It is easy to make money, but the same cannot be said of making meaning in your life.’’</p><br/>
<p className="amog-parag pt-1">Through this endeavour, I aspire to bring meaningful change and transformation
to the community I care deeply about.’’</p>
       
        </Col>
      </Row>
      </div>
  </Container>
</section>
{/* <!-- End Amogh_Inamdar Section --> */}

{/* <!-- Start Our Team Section --> */}
<section className="about-bg">
<img src={top} alt="banner" className="topdesign" width={"20%"} />
<img src={right_bottom} alt="banner" className="rightbottomdesign" width={"20%"} />
      <Container >
        <Row>
        <div className="gap">
      <h1 className="about-heading mb-5 aos-init  aos-animate" data-aos="fade-up">Our Team</h1>
     <Row>
     <Col lg={3} md={3} sm={12} xs={12} className="aos-init  aos-animate" data-aos="fade-up">
     <div className="our_team_cont">
     <img src={Vishnukant} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Dr. Vishnukant Chatpalli</h5>
     <p className="ourteam-sub-heading pb-3">Chief Advisor</p>
     </div>
     </Col>
     <Col lg={3} md={3} sm={12} xs={12} className="aos-init  aos-animate" data-aos="fade-up">
     <div className="our_team_cont">
     <img src={Karan} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Karan Kumar</h5>
     <p className="ourteam-sub-heading pb-3">Investor, Business Head</p>
     </div>
     </Col>
     <Col lg={3} md={3} sm={12} xs={12} className="aos-init  aos-animate" data-aos="fade-up">
     <div className="our_team_cont">
     <img src={Indumathi} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4"> Dr. Indumathi Rao</h5>
     <p className="ourteam-sub-heading">Mentor</p>
     </div>
     </Col>
     <Col lg={3} md={3} sm={12} xs={12} className="aos-init  aos-animate" data-aos="fade-up">
     <div className="our_team_cont">
     <img src={Guddushastri} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">G. M Inamdar</h5>
     <p className="ourteam-sub-heading">Director</p>
     </div>
     </Col>
     <Col lg={3} md={3} sm={12} xs={12} className="aos-init  aos-animate" data-aos="fade-up">
     <div className="our_team_cont">
     <img src={Shailaja} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Shailaja Inamdar</h5>
     <p className="ourteam-sub-heading">Product</p>
     </div>
     </Col>
     </Row>
    </div>
    </Row>
    </Container>
    </section>
    {/* <!-- End Our Team Section --> */}

    {/* <!-- ======= Start Contact Section ======= --> */}
    <section id="Contact" className="home-bg gap">
    <img src={bottom} alt="banner" className="bottomdesign" width={"20%"} />
    <img src={right_top} alt="banner" className="righttopdesign" width={"20%"} />
      <div >
        <div className="reach-contner aos-init  aos-animate" data-aos="fade-up">
        <h1 className="reach-heading">Reach us at</h1>
        <p className="reach-para"><FiMail />  ai@ableproindia.com</p>
        <p className="reach-para"><IoMdCall />  +91 98441 32652 | +91 97395 50801</p>
        </div>
      </div>
    </section>
{/* <!-- ======= Start Contact Section ======= --> */}

{/* <!-- ======= Start footer_logo Section ======= --> */}
<section className="about-bg gap1">
<img src={designleft} alt="banner" className="lefttdesign" width={"20%"} />
<img src={right_bottom} alt="banner" className="rightbottomdesign" width={"20%"} />
<img src={banner} alt="banner" className="logo_icon" width={"200"} />
<h1 className="footer-heading text-center pt-2">AblePro Solutions</h1>
<p className="footer-para text-center pt-1">Enable, Engage, Empower</p>
</section>
{/* <!-- ======= End footer_logo Section ======= --> */}

{/* <!-- ======= Start Footer Section ======= --> */}
<section className="home-bg">
  <p className="footer">ABLEPRO SOLUTIONS PVT. LTD. | ALL RIGHTS RESERVED | 2024</p>
</section>
{/* <!-- ======= End Footer Section ======= --> */}

  </>
  )
}

export default Home
